:root {
    // Hotfix for Tailwind 3's transform utilities being buggy in Safari <=14
    // https://github.com/tailwindlabs/tailwindcss/discussions/8788
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    transition: opacity var(--transition-duration, 0.3s) theme('transitionTimingFunction.in-out-cubic');
}

.no-js img.lazyload[data-src],
.no-js img.lazyload[data-srcset] {
    display: none;
}

html.outline,
html.no-outline * {
    outline: none !important;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

svg {
    display: block;
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button {
    appearance: none;
    font-weight: inherit;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none !important;
}

select {
    appearance: none;
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

table {
    th, td {
        text-align: left;
        padding: 0;
        font-weight: normal;
        vertical-align: top;
    }
}

strong, b {
    font-weight: normal;
}

i, em {
    font-style: italic;
}

.hit::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

img, video, svg {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

a, button {
    &[disabled],
    &[aria-disabled="true"] {
        cursor: default;
    }
    html.using-touch & {
        touch-action: manipulation;
    }
}

details > summary {
    list-style: none;
    cursor: pointer;
}

details > summary > * {
    display: inline;
}

details > summary::-webkit-details-marker {
    display: none;
}

.font-polet-tittel {
    font-weight: 700;
}

.font-polet-ingress {
    font-weight: 500;
}

.link {
    text-decoration: underline;
    text-decoration-thickness: 0.1em;
    text-underline-offset: 0.15em;
    .group:active &,
    &:active {
        text-underline-offset: 0.1em;
    }
    @media (hover: hover) and (pointer: fine) {
        .group:hover &,
        &:hover {
            text-underline-offset: 0.1em;
        }
    }
}

.link-alt {
    text-decoration: underline;
    text-decoration-thickness: 0.1em;
    text-underline-offset: 0.15em;
    text-decoration-color: transparent;
    .group:active &,
    &:active {
        text-decoration-color: currentColor;
        text-underline-offset: 0.1em;
        transition: none !important;
    }
    @media (hover: hover) and (pointer: fine) {
        transition: text-decoration-color 0.2s;
        .group:hover &,
        &:hover {
            text-decoration-color: currentColor;
        }
    }
}

.skip-link:not(:focus) {
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    height: 1px;
    overflow: hidden;
    padding: 20px;
    position: absolute;
    text-decoration: underline;
    top: 1rem;
    left: 1rem;
    width: 1px;
    z-index: 999;
}

.squish {
    transition: transform 0.1s linear;
    transform: scale(1);
    &:active,
    .group:active & {
        transform: scale(calc(1 - var(--squishyness, 0.03)));
    }
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        transform: none !important;
    }
}

html.js:not(.js-error) [data-theme] {
    @media screen and (prefers-reduced-motion: no-preference) and (update: fast) {
        background-color: transparent;
    }
}

html.transition-theme {
    &, [class*="text-theme-"], [class*="bg-theme-"] {
        transition: color var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad'), background-color var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad') !important;
    }
    [class*="fill-theme-"] {
        transition: fill var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad') !important;
    }
    .invert-when-dark,
    .grayscale-when-dark {
        transition: filter var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad');
        > * {
            transition: opacity var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad') !important;
        }
    }
}

// Reveals
@media screen and (prefers-reduced-motion: no-preference) and (update: fast) {
    html.js:not(.js-error):not(.is-preview.is-iframe) [data-reveal]:not([data-revealed]) {
        opacity: 0;
    }
}
