@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    .grayscale-when-dark {
        html.theme-blue &,
        html.theme-green &,
        html.theme-brown &,
        html.theme-burgundy &,
        html.theme-teal & {
            @apply motion-safe-fast:grayscale;
            > * {
                @apply motion-safe-fast:opacity-30;
            }
        }
    }

    .invert-when-dark {
        html.theme-blue &,
        html.theme-green &,
        html.theme-brown &,
        html.theme-burgundy &,
        html.theme-teal & {
            @apply invert;
        }
    }

}
