.wysiwyg {
    p, ul, ol {
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }
    h2 {
        @apply h3;
        margin-bottom: 0.6em;
        &:not(:first-child) {
            margin-top: 1.5em;
        }
    }
    h3 {
        font-weight: 500;
    }

    a:not([class]) {
        @apply link;
    }

    ul li,
    ol li {
        padding-left: 3.125em;
        margin-bottom: 1em;
        position: relative;
        text-wrap: balance;
        &::before {
            content: "";
            display: block;
            width: 1.5em;
            height: 1.5em;
            position: absolute;
            left: 0; top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul li::before {
        background-image: url('../img/circle.svg');
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        left: 0.55em;
    }

    ol {
        counter-reset: list-number;
    }

    ol li::before {
        counter-increment: list-number;
        content: counter(list-number);
        text-align: right;
        font-family: theme('fontFamily.polet-tittel');
        font-size: 1.5em;
        left: -0.33em;
        top: -0.35em;
        @screen l {
            font-size: 1.3333em;
            left: -0.25em;
            top: -0.25em;
        }
    }
}
